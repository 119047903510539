/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../@lekoarts/gatsby-theme-cara/components/menu-bar';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../@lekoarts/gatsby-theme-cara/constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import BlogBackground from '../@lekoarts/gatsby-theme-cara/components/blog-background';
import GenericFooter from '../@lekoarts/gatsby-theme-cara/components/generic-footer';
import { graphql } from 'gatsby';
import ContactForm from '../@lekoarts/gatsby-theme-cara/components/contact-form';

const ThankYou = ({ data }) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Testimonials" />
      <Layout className="Blog-post-page">
        <MenuBar />
        <BlogBackground offset={0} />
        <div className="Pricing-content text-center">
          <h1>Testimonials</h1>
        </div>

        <div className="Pricing-content text-left">
          <p>
            "Katie Coleman and Gavin Bintz created a beautiful new website for The Coleman
            School of Dance. The website is both professional, sophisticated, smart, and
            beautiful. I feel really proud that this stunning website represents the work
            of the exceptional dancers at the studio. It's a great feeling when you know
            you are doing right by your students. Katie and Gavin are inspirational to
            work with. They are excited to make your site the highest of qualities in a
            timely manner. They also make sure to get changes done quickly and accurately.
            Besides that, they are just lovely people to work with whom I enjoy talking to
            and designing with."
            <br />
            <br />
            -Christina Coleman,{' '}
            <a href="https://www.thecolemandance.com/" target="_blank">
              thecolemandance.com
            </a>
          </p>
          <br />
          <p>
            "Swirl Web Design did a fantastic job of designing the website and business
            cards for Coastal Bend Dyslexia Therapy! They had lots of different options
            and flexibility and were able to meet the needs of my small business. They
            have different website options you can consider to best help your business
            shine, no matter what size it is!"
            <br />
            <br />
            -Carla Sandifer,{' '}
            <a href="https://www.cbdyslexia.com/" target="_blank">
              cbdyslexia.com
            </a>
          </p>
          <br />
          <p>
            "A huge shout out to Katie and Gavin at Swirl Creative Web Design!!!!!! They
            are incredible people who are extremely creative and technically savvy! They
            have beautiful hearts! This company is awesome!!!’ Can’t say enough good words
            about them!"
            <br />
            <br />
            -Jane Ethridge,{' '}
            <a href="https://www.janesheartlifecoach.com/" target="_blank">
              janesheartlifecoach.com
            </a>
          </p>
        </div>
        <br />
        <br />
        <div>
          <ContactForm />
          <GenericFooter />
        </div>
      </Layout>
    </ThemeProvider>
  );
};

export default ThankYou;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "Coffee.png" }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
